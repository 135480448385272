import { css } from 'styled-components';
import {
  AiFillGithub,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from 'react-icons/ai';
import { SiStackoverflow } from 'react-icons/si';

import styled from 'styled-components';

type SocialMediaProps = {
  drawer?: boolean;
  width?: string;
};

const SocialMedia = ({ drawer, width }: SocialMediaProps) => {
  return (
    <Wrapper drawer={drawer}>
      <SocialIcons href="https://github.com/bocarw121">
        <StyledGithubIcon size="3rem" />
      </SocialIcons>
      {/* <SocialIcons href="https://www.linkedin.com/in/bocar-wane-a5871a1b4/">
        <StyledLinkedinIcon width={width} size="3rem" />
      </SocialIcons> */}
      <SocialIcons href="https://twitter.com/BocarWane15">
        <StyledTwitterIcon width={width} size="3rem" />
      </SocialIcons>
      <SocialIcons
        color="white"
        href="https://stackoverflow.com/users/14915150/bocar"
      >
        <StyledStackoverflowIcon width={width} size="3rem" fill="orange" />
      </SocialIcons>
    </Wrapper>
  );
};

const Wrapper = styled.div<SocialMediaProps>`
  @media screen and (max-width: 890px) {
    ${({ drawer }) =>
      drawer
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }
`;

const SocialIcons = styled.a`
  /* transition: 0.3s ease; */
  color: white;
  border-radius: 50px;
  padding: 0 1.2rem;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    /* color: #a9caee; */
  }

  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 1.5rem;
    }
  }
`;
const StyledGithubIcon = styled(AiFillGithub)`
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #212d45;
    scale: 1.1;
  }
  @media screen and (max-width: 1280px) {
    width: 2.5rem;
  }
  @media screen and (max-width: 930px) {
    width: 2rem;
  }

  @media screen and (max-width: 768px) {
    width: ${({ width }) => width && width};
  }
`;

const StyledLinkedinIcon = styled(AiFillLinkedin)`
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #212d45;
    scale: 1.1;
  }
  @media screen and (max-width: 1280px) {
    width: 2.5rem;
  }
  @media screen and (max-width: 930px) {
    width: 2rem;
  }

  @media screen and (max-width: 768px) {
    width: ${({ width }) => width && width};
  }
`;

const StyledStackoverflowIcon = styled(SiStackoverflow)`
  transition: all 0.2s ease-in-out;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 1280px) {
    width: 2.5rem;
  }
  @media screen and (max-width: 930px) {
    width: 2rem;
  }

  @media screen and (max-width: 768px) {
    width: ${({ width }) => width && width};
  }
`;

const StyledTwitterIcon = styled(AiFillTwitterCircle)`
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #212d45;
    scale: 1.1;
  }
  @media screen and (max-width: 1280px) {
    width: 2.5rem;
  }
  @media screen and (max-width: 930px) {
    width: 2rem;
  }

  @media screen and (max-width: 768px) {
    width: ${({ width }) => width && width};
  }
`;

export default SocialMedia;
