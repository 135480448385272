import styled from 'styled-components';
import { Divider } from '../../components/Divider';
import { ParagraphText } from '../../components/ParagaraphText';
import { TitleText } from '../../components/TitleText';

export const Hero = () => {
  return (
    <Wrapper>
      <TitleText>
        Welcome To <br />
        My Personal Portfolio
      </TitleText>
      <ParagraphText>
        Hi, I'm Bocar, an experienced Full-Stack Web Developer passionate about
        building user-friendly and responsive applications. Whether you're
        looking for a dedicated professional to join your team or need someone
        to take your web projects to the next level, I'm here to help. Take a
        look at my work in the projects section, and please reach out if you
        have questions or job opportunities to discuss.
      </ParagraphText>
      <Divider />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10rem;
`;
