import { useState } from 'react';
import styled from 'styled-components';

import { TitleText } from '../../components/TitleText';
import { projects } from '../../constants/projectData';

import { Project } from './Project';

import { Box, Tab, Tabs } from '@mui/material';

const openSourceProjects = projects.filter((project) => project.openSource);
const regularProjects = projects.filter((project) => !project.openSource);

export const Projects = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // const isFeatured = newValue === 0;
    // const isOpenSource = newValue === 1;
    // set to the query string
    setValue(newValue);
  };
  return (
    <Wrapper>
      <TitleText>Projects</TitleText>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Featured"
              sx={{
                fontSize: '1.5rem',
                color: '#fff',
              }}
            />
            <Tab
              label="Open Source"
              sx={{
                fontSize: '1.5rem',
                color: '#fff',
              }}
            />
          </Tabs>
        </Box>
      </Box>

      {/* <InputWrapper>
        <Input type="text" />
      </InputWrapper> */}

      {value === 0 && (
        <ProjectContainer>
          {regularProjects.map((project) => {
            return <Project key={project.id} project={project} />;
          })}
        </ProjectContainer>
      )}

      {value === 1 && (
        <ProjectContainer>
          {openSourceProjects.map((project) => {
            return <Project key={project.id} project={project} />;
          })}
        </ProjectContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ProjectContainer = styled.div`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  place-items: center;
  grid-auto-rows: auto;
  gap: 2rem;

  @media screen and (max-width: 1020px) {
    margin: 2rem 0;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }
`;

const Input = styled.input`
  width: 400px;
  height: 50px;
  background: transparent;
  color: #fff;
  border: 1px solid #ffe;
  border-radius: 5px;
  padding: 0 1rem;
  margin: 2rem 0;
  font-size: 1.5rem;

  &:focus {
    outline: none;
    border: 1px solid #;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
