import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useAppContext } from '../../context/AppContext';
import { Divider } from '../Divider';

type NavLinksProps = {
  drawer?: boolean;
};

const NavLinks = ({ drawer }: NavLinksProps) => {
  const { closeDrawer } = useAppContext();
  return (
    <Links drawer={drawer}>
      <NavLink onClick={closeDrawer} to="/projects">
        Projects
      </NavLink>
      {drawer && <Divider drawer />}
      <NavLink onClick={closeDrawer} to="/technologies">
        Technologies
      </NavLink>
      {drawer && <Divider drawer />}
      <NavLink onClick={closeDrawer} to="/contact">
        Contact
      </NavLink>
      {drawer && <Divider drawer />}
      {drawer && <Divider drawer />}
      {/* <a href="/BocarWaneResume.pdf" download>
        Resume
      </a> */}
    </Links>
  );
};

const Links = styled.div<NavLinksProps>`
  a {
    text-decoration: none;
    color: white;
    padding: 0 5rem;
    font-size: clamp(1.8rem, 1.5535rem + 0.493vw, 2.5rem);
    position: relative;
    // left: 4.8rem;

    &:hover {
      color: #a9caee;
    }

    @media screen and (max-width: 1280px) {
      padding: 0 2rem;
    }

    @media screen and (max-width: 990px) {
      left: 0;
      padding: 0 1rem;
    }

    @media screen and (max-width: 890px) {
      ${({ drawer }) =>
        drawer
          ? css`
              display: block;
              padding: 2rem;
              margin-top: 2rem;
              display: flex;
              justify-content: center;
            `
          : css`
              display: none;
            `}
    }
  }
`;

export default NavLinks;
