import styled from 'styled-components';

import { ParagraphText } from '../../components/ParagaraphText';
import { TitleText } from '../../components/TitleText';
import { TechIcon } from './TechIcon';

export const Technologies = () => {
  return (
    <Wrapper>
      <TitleText size="6.5rem">Technologies</TitleText>
      <ParagraphText>
        I've worked with a range of technologies in software and web
        development, from Front-end to Back-end.
      </ParagraphText>
      <TechIconGrid>
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"
          alt="Html logo icon"
          title="Html"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"
          alt="Css logo icon"
          title="Css"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
          alt="JavaScript logo icon"
          title="JavaScript"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
          alt="TypeScript logo icon"
          title="TypeScript"
        />
        <TechIcon
          source="https://www.rust-lang.org/logos/rust-logo-128x128.png"
          alt="Rust logo icon"
          title="Rust"
        />

        <TechIcon
          source="https://raw.githubusercontent.com/devicons/devicon/1119b9f84c0290e0f0b38982099a2bd027a48bf1/icons/cplusplus/cplusplus-original.svg"
          alt="C++ logo icon"
          title="C++"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
          alt="React logo icon"
          title="React"
        />
        <TechIcon
          source="/react-native-logo.png"
          alt="React Native logo icon"
          title="React Native"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg"
          alt="Redux logo icon"
          title="Redux"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg"
          alt="Node logo icon"
          title="Node"
        />
        <TechIcon
          source={require('../../express.svg').default}
          alt="Express js logo icon"
          title="Express"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg"
          alt="MongoDB logo icon"
          title="MongoDB"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg"
          alt="MySql logo icon"
          title="MySql"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original-wordmark.svg"
          alt="Redis logo icon"
          title="Redis"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg"
          alt="Docker logo icon"
          title="Docker"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nginx/nginx-original.svg"
          alt="Nginx logo icon"
          title="Nginx"
        />
        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain-wordmark.svg"
          alt="Git logo icon"
          title="Git"
        />

        <TechIcon
          source="https://raw.githubusercontent.com/devicons/devicon/1119b9f84c0290e0f0b38982099a2bd027a48bf1/icons/ansible/ansible-plain-wordmark.svg"
          alt="Ansible logo icon"
          title="Ansible"
          bg="white"
        />

        <TechIcon
          source="https://raw.githubusercontent.com/devicons/devicon/1119b9f84c0290e0f0b38982099a2bd027a48bf1/icons/jest/jest-plain.svg"
          alt="Jest logo icon"
          title="Jest"
        />

        <TechIcon
          source="https://yew.rs/img/logo.png"
          alt="Yew framework"
          title="Yew framework"
        />

        <TechIcon
          source="https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg"
          alt="Next JS"
          title="Next JS"
          bg="white"
        />

        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kotlin/kotlin-plain-wordmark.svg"
          alt="Kotlin logo icon"
          title="Kotlin"
          bg="white"
        />

        <TechIcon
          source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/swift/swift-original-wordmark.svg"
          alt="Swift logo icon"
          title="Swift"
          bg="white"
        />
      </TechIconGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TechIconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 2rem;
  height: auto;
  background: #1e2a4291;
  padding: 2rem;
  place-items: center;
  margin-bottom: 8rem;

  img {
    height: 8rem;
    width: 8rem;
  }

  @media screen and (min-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    img {
      height: 10rem;
      width: 10rem;
    }
  }

  @media screen and (min-width: 1080px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;

    img {
      height: 12rem;
      width: 12rem;
    }
  }
`;
