import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext({
  isOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  projectTitle: '',
  setProjectTitle: (project: string) => {},
});

export const ProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [projectTitle, setProjectTitle] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const values = {
    isOpen,
    openDrawer,
    closeDrawer,
    projectTitle,
    setProjectTitle,
  };

  return (
    <ProjectContext.Provider value={values}>{children}</ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);
