import { List, ListItem, SwipeableDrawer } from '@mui/material';
import { useEffect } from 'react';
import { useAppContext } from '../context/AppContext';
import { useWindowDimesions } from '../hooks/useWindowDimensions';
import NavLinks from './NavBar/NavLinks';
import SocialMedia from './NavBar/SocialMedia';

export const NavDrawer = () => {
  const { isOpen, closeDrawer, openDrawer } = useAppContext();
  const { width, height } = useWindowDimesions();

  useEffect(() => {
    if (width > 768) {
      closeDrawer();
    }
  }, [width]);

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={openDrawer}
      onClose={closeDrawer}
      PaperProps={{ style: { background: '#0f1624' } }}
    >
      <List disablePadding>
        <ListItem disableGutters>
          <SocialMedia drawer />
        </ListItem>
      </List>
      <List disablePadding>
        <ListItem disableGutters>
          <NavLinks drawer />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};
