import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
  ${css`
    ${reset}

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    html {
      font-size: 62.5%;
      scroll-behavior: smooth;
    }

    body {
      font-size: 1.6rem;
      background: #0f1624;
      color: #fff;
      min-width: 360px;
    }
  `}
`;
