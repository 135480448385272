import { motion } from 'framer-motion';
import { Hero } from './Hero';

export const Home = () => {
  return (
    <motion.div>
      <Hero />
    </motion.div>
  );
};
