import styled from 'styled-components';

export const ParagraphText = styled.p`
  max-width: 800px;
  font-size: clamp(1.8rem, 1.6615rem + 0.3846vw, 2.4rem);
  line-height: 40px;
  font-weight: 300;
  padding-bottom: 3.6rem;
  color: rgba(255, 255, 255, 0.5);

  @media screen and (max-width: 768px) {
    line-height: 34px;
  }
`;
