import {
  SwipeableDrawer,
  Stack,
  IconButton,
  Link,
  Button,
} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { projects } from '../constants/projectData';

import { useProjectContext } from '../context/ProjectContext';
import { useWindowDimesions } from '../hooks/useWindowDimensions';

import styled from 'styled-components';
import { Divider } from './Divider';
import { Container } from '@mui/system';

type ProjectType = {
  image: string;
  title: string;
  description: string;
  stack: string[];
  features: string[];
  github: string;
  live: string;
  inProgress: string;
};

export const ProjectInfoDrawer = () => {
  const { isOpen, closeDrawer, openDrawer, projectTitle } = useProjectContext();
  const { width } = useWindowDimesions();
  const project = projects.find((item) => item.title === projectTitle);

  const responsiveWidth = width > 768 ? '50%' : '100%';
  const isSmallScreen = width < 768;

  const buttonStyle = {
    marginRight: 4,
    marginLeft: 4,
    marginTop: 2,
    width: '100px',
    fontSize: '1.5rem',
    '&:hover': {
      background: 'linear-gradient(270deg, #f46737 0%, #945dd6 100%)',
      color: 'white',
      transition: 'all 0.2s ease-in-out',
    },
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={openDrawer}
      onClose={closeDrawer}
      PaperProps={{ style: { background: '#0f1624', width: responsiveWidth } }}
      transitionDuration={500}
    >
      {/* close button */}
      {isSmallScreen && (
        <IconButton aria-label="close" onClick={closeDrawer}>
          <CloseIcon
            style={{
              color: 'white',
              position: 'absolute',
              right: 30,
              top: 20,
              fontSize: '3rem',
            }}
          />
        </IconButton>
      )}
      <div>
        <MainTitle>{project?.title}</MainTitle>
        <Divider drawer />
        <Stack spacing={2}>
          <Description as="h4">{project?.description}</Description>
        </Stack>
        <Container>
          <TechContainer>
            <SectionTitle>Technologies used:</SectionTitle>
            {project?.stack.map((tech, i) => (
              <Tech key={tech}>
                <span>❖</span> {tech}
              </Tech>
            ))}
          </TechContainer>
          <Divider drawer />
          <FeatureContainer>
            <SectionTitle>Features:</SectionTitle>
            {project?.features.map((feature) => (
              <Feature
                style={{
                  lineHeight: isSmallScreen ? '1.4' : undefined,
                }}
                key={feature}
              >
                <span>❖</span> {feature}
              </Feature>
            ))}
          </FeatureContainer>
        </Container>
        <LinkSection>
          {project?.github && (
            <Button
              component={Link}
              variant="contained"
              target="_blank"
              href={project?.github}
              rel="noreferrer"
              sx={buttonStyle}
            >
              Github
            </Button>
          )}
          {project?.live && (
            <Button
              component={Link}
              variant="contained"
              target="_blank"
              href={project?.live}
              rel="noreferrer"
              sx={buttonStyle}
            >
              Live
            </Button>
          )}
          {project?.inProgress && <h4> In Developement</h4>}
        </LinkSection>
      </div>
    </SwipeableDrawer>
  );
};

const Title = styled.h2`
  background: linear-gradient(
    121.57deg,
    #ffffff 18.77%,
    rgba(255, 255, 255, 0.66) 60.15%
  );
  -webkit-background-clip: text;

  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MainTitle = styled(Title)`
  margin: 2rem;
  font-size: clamp(3rem, 2.4rem + 1.6667vw, 4rem);
  text-align: center;
`;

const Description = styled(Title)`
  margin: 4rem 8rem 0rem 8rem;
  text-align: center;
  color: white;
  font-size: 2.4rem;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    margin: 2rem;
  }
`;

const TechContainer = styled.ul`
  margin: 4rem 0;
`;

const SectionTitle = styled(Title)`
  font-size: 2.2rem;
`;

const Tech = styled.li`
  display: inline-block;
  margin: 0.5rem;
  font-size: 1.8rem;

  color: white;
  margin-top: 2rem;

  span {
    font-size: 1.5rem;
    margin-right: 0.2rem;
  }
`;

const FeatureContainer = styled.ul`
  margin: 4rem 0;

  h2 {
    margin-bottom: 1.5rem;
  }
`;

const Feature = styled.li`
  color: white;
  padding: 1rem 0 1rem 2rem;
  font-size: 1.8rem;

  span {
    font-size: 1.5rem;
    margin-right: 0.2rem;
  }
`;

const LinkSection = styled.div`
  display: flex;
  justify-content: center;

  h4 {

    font-size: 1.8rem;
    color: white;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    border: 1px solid white;
    border-radius: 1rem;

    &:hover {
      background: linear-gradient(270deg, #f46737 0%, #945dd6 100%);
      color: white;
      transition: all 0.2s ease-in-out;
    }
  }
   
  }
`;
