import styled, { css } from 'styled-components';

type DividerProps = {
  drawer?: boolean;
};

export const Divider = styled.div<DividerProps>`
  background: linear-gradient(270deg, #f46737 0%, #945dd6 100%);
  height: 6px;
  ${({ drawer }) =>
    drawer
      ? css`
          margin-top: 0rem;
          height: 2px;
        `
      : css`
          margin-top: 2rem;
        `};
`;
