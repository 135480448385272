import { Grid } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { Link } from 'react-router-dom';
import typography from 'theme/base/typography';
import maintenance from 'images/error-404.png';

export const Maintenance = () => {
  const { d1, d3, d4, d5 } = typography;

  return (
    <SoftBox my={18} height="calc(100vh - 18rem)">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} container alignItems="center">
          <Grid item xs={12} lg={5}>
            <SoftBox
              fontSize={{
                xs: d5.fontSize,
                sm: d4.fontSize,
                md: d3.fontSize,
                lg: d1.fontSize,
              }}
              lineHeight={1.2}
            ></SoftBox>
            <SoftTypography variant="h2" color="text" fontWeight="bold">
              Website is under maintenance
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="h4" color="text" opacity={0.8}>
                There is no content to display at the moment. Please check back
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <SoftBox
              component="img"
              src={maintenance}
              alt="error-maintenance"
              width="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    </SoftBox>
  );
};
