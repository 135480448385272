import { forwardRef } from "react";

// prop-types is a library for typechecking of props
// Custom styles for SoftBox
import SoftBoxRoot from "components/SoftBox/SoftBoxRoot";
import React from "react";
import theme from "theme";

interface SoftBoxProps {
  variant?: "contained" | "gradient";
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
}

const SoftBox = forwardRef<HTMLDivElement, SoftBoxProps | any>(
  (
    { variant, bgColor, color, opacity, borderRadius, shadow, ...rest },
    ref
  ) => (
    <SoftBoxRoot
      theme={theme}
      {...rest}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  )
);

export default SoftBox;
