import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Divider } from '../../components/Divider';
import { useProjectContext } from '../../context/ProjectContext';

import { ProjectType } from 'types';

type ProjectProps = {
  project: ProjectType;
};

export const Project = ({ project }: ProjectProps) => {
  const { openDrawer, setProjectTitle } = useProjectContext();

  const descriptionLength = project.description.length;

  return (
    <Wrapper
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.1, type: 'spring', bounce: 0.3 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0.5, scale: 0.5 },
      }}
    >
      {project.isBeta && <Beta>Beta</Beta>}
      <Image
        src={project.image}
        openSource={project.openSource}
        alt=""
        isLarge={project.isLarge}
      />
      <TitleSection>
        <Title>{project.title}</Title>

        <Divider />
      </TitleSection>

      <DescriptionSection>
        <Description isLong={descriptionLength > 280}>
          {project.shortDescription}
        </Description>
      </DescriptionSection>

      <Button
        onClick={() => {
          setProjectTitle(project.title);
          openDrawer();
        }}
        style={{ marginTop: '2rem', fontSize: '1.2rem' }}
        variant="contained"
      >
        More Information
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  border: 1px solid white;
  width: 400px;
  min-height: 550px;
  padding-bottom: 2rem;
  border-radius: 1rem;
  box-shadow: 3px 3px 20px rgba(80, 78, 78, 0.5);
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    width: 320px;
  }

  button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Image = styled.img<{ openSource: boolean; isLarge: boolean }>`
  width: ${(props) => (props.openSource ? '200px' : '100%')};
  margin: ${(props) => props.openSource && '0 auto'};

  height: ${(props) => (props.openSource ? '200px' : '100%')};
  height: ${(props) => props.isLarge && '200px'};
  min-height: ${(props) => (props.openSource ? '60px' : '280px')};
  object-fit: cover;
  overflow: hidden;
  size: ${(props) => (props.openSource ? '60px' : '200px')};
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  padding: 0.01rem;
  margin-top: ${(props) => props.openSource && '50px'};
`;

const TitleSection = styled.div`
  position: relative;
`;

const DescriptionSection = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  margin-top: 2rem;
  font-size: clamp(2rem, 1.9077rem + 0.2564vw, 2.4rem);
  font-weight: bold;
`;

const Description = styled.p<{ isLong: boolean }>`
  ${({ isLong }) =>
    isLong
      ? css`
          max-height: 8.35rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        `
      : css`
          white-space: normal;
        `}
  max-width: 100%;
  margin-top: 2rem;
  font-size: 2.1rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
`;

const Beta = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff0000;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;
