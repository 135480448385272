import React, { createContext, useContext, useEffect, useState } from 'react';
import { IpApi } from './types';

const AppContext = createContext({
  isOpen: false,
  isBlocked: false,
  openDrawer: () => {},
  closeDrawer: () => {},
});

const blockedCities = [
  'Ottawa',
  'Old Ottawa East',
  'Old Ottawa South',
  'Old City of Ottawa',
  'Old Ottawa',
  '(Old) Ottawa',
  'Nepean',
  'Kanata',
  'Stittsville',
  'Orleans',
  'Gloucester',
  'Osgoode',
  'Manotick',
  'Barrhaven',
  'Cumberland',
  'Rideau',
  'Osgoode Township',
  'Cumberland',
  'Carp',
];

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean | null>(null);

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchUserData() {
    try {
      const res = await fetch('https://ipapi.co/json/');
      const data = (await res.json()) as IpApi;

      if (blockedCities.includes(data.city)) {
        setIsBlocked(true);
        sendData(data);
        localStorage.setItem('user', data.ip);
      } else {
        setIsBlocked(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function sendData(data: IpApi) {
    try {
      if (localStorage.getItem('user') === data.ip) return;

      await fetch('https://old-sun-5973.fly.dev/logger', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
    } catch (error) {
      console.log(error);
    }
  }

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const values = {
    isOpen,
    isBlocked,
    openDrawer,
    closeDrawer,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
