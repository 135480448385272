import React from 'react';
import styled from 'styled-components';

type TitleProps = { children: React.ReactNode; size?: string };

export const TitleText = ({ children }: TitleProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.h2`
  font-weight: 800;
  font-size: clamp(3rem, 2.1923rem + 2.2436vw, 6.5rem);
  line-height: 72px;

  width: max-content;
  max-width: 100%;
  background: linear-gradient(
    121.57deg,
    #ffffff 18.77%,
    rgba(255, 255, 255, 0.66) 60.15%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  padding: 6rem 0 1rem;

  @media screen and (max-width: 768px) {
    line-height: 48px;
  }
`;
