import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useAppContext } from '../../context/AppContext';
import NavLinks from './NavLinks';
import SocialMedia from './SocialMedia';
export const NavBar = () => {
  const { openDrawer } = useAppContext();

  return (
    <Wrapper>
      <LogoTitle to="/">Bocar Wane's Portfolio</LogoTitle>
      <NavLinks />
      <SocialMedia />
      <HamburgerWrapper>
        <IconButton onClick={openDrawer}>
          <GiHamburgerMenu size={28} color="white" />
        </IconButton>
      </HamburgerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  height: 8rem;
  padding: 0 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1420px) {
    padding: 0 2rem;
  }
`;

const LogoTitle = styled(Link)`
  font-family: Space Grotesk, sans-serif;
  font-size: clamp(2rem, 1.6479rem + 0.7042vw, 3rem);
  color: white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
`;

const HamburgerWrapper = styled.div`
  display: none;
  @media screen and (max-width: 890px) {
    display: block;
  }
`;
