import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Analytics } from '@vercel/analytics/react';

import theme from 'theme';
import App from './components/App';
import { GlobalStyles } from './components/GlobalStyles';
import { AppProvider } from './context/AppContext';
import { FormProvider } from './context/FormContext';
import { ProjectProvider } from './context/ProjectContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyles />

    <BrowserRouter>
      <AppProvider>
        <FormProvider>
          <ProjectProvider>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </ProjectProvider>
        </FormProvider>
      </AppProvider>
      <Analytics />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
