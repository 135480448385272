import Typography from "@mui/material/Typography";
import { styled, Theme } from "@mui/material/styles";

export default styled(Typography)(
  ({ theme, ownerState }: { ownerState: any; theme: Theme }) => {
    const { palette, typography, functions } = theme as any;
    const {
      color,
      textTransform,
      verticalAlign,
      fontWeight,
      opacity,
      textGradient,
    } = ownerState;

    const { gradients, transparent } = palette;
    const {
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
    } = typography;
    const { linearGradient } = functions;

    // fontWeight styles
    const fontWeights = {
      light: fontWeightLight,
      regular: fontWeightRegular,
      medium: fontWeightMedium,
      bold: fontWeightBold,
    };

    // styles for the typography with textGradient={true}
    const gradientStyles = () => `
    opacity: ${opacity};
    text-transform: ${textTransform};
    vertical-align: ${verticalAlign};
    text-decoration: none;
    ${
      color !== "inherit" &&
      color !== "text" &&
      color !== "white" &&
      gradients[color]
        ? `background-image: ${linearGradient(
            gradients[color].main,
            gradients[color].state
          )};
           display: inline-block;
           -webkit-background-clip: text;
           -webkit-text-fill-color: ${transparent.main};
           position: relative;
           z-index: 1;`
        : `background-image: ${linearGradient(
            gradients.dark.main,
            gradients.dark.state
          )};`
    }
  `;

    return `
    opacity: ${opacity};
    text-transform: ${textTransform};
    vertical-align: ${verticalAlign};
    text-decoration: none;
    color: ${
      color === "inherit" || !palette[color] ? "inherit" : palette[color].main
    };
    ${fontWeights[fontWeight] ? `font-weight: ${fontWeights[fontWeight]};` : ""}
    ${textGradient ? gradientStyles() : ""}
  `;
  }
);
