import { useRef, useState } from 'react';
import {
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';

import emailjs from '@emailjs/browser';
import styled from 'styled-components';

import { useFormContext } from '../context/FormContext';
import { toast } from 'react-toastify';

export const Contact = () => {
  const { formData, handleChange, clearForm } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const notify = (message: string) => toast(message);

  const sendEmail = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_TEMPLATE_ID as string,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY as string
      )
      .then(
        (result) => {
          if (result.text === 'OK') {
            setIsLoading(false);
            toast.success('Message sent successfully');
            clearForm();
          }
        },
        (error) => {
          setIsLoading(false);
          toast.error('Error sending message, please try again');
        }
      );
  };

  return (
    <Form
      ref={form}
      onSubmit={sendEmail}
      // initial="out"
      // animate="in"
      // exit="out"
      // variants={animationSlideFromTop}
      // transition={bounce}
    >
      <StyledCard>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Contact
          </Typography>
          <Typography variant="h6" color="GrayText" gutterBottom>
            Send me a message and I will get back to you shortly.
          </Typography>

          <Grid container spacing={1}>
            <Grid xs={12} item>
              <TextField
                label="Name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>

            <Grid xs={12} item>
              <TextField
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                type="number"
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid xs={12} item>
              <TextField
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter a subject"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                multiline
                rows={4}
                label="Message"
                name="message"
                placeholder="Type your message here"
                value={formData.message}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <Button
                style={{
                  top: 15,
                  fontSize: 16,
                  borderRadius: 10,
                  outline: 'none',
                }}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                {isLoading ? (
                  <CircularProgress style={{ color: 'white' }} size={24} />
                ) : (
                  'Send Message'
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </Form>
  );
};

const Form = styled.form`
  margin-top: 15rem;

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
  }
`;

const StyledCard = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding: 2rem 0.5rem;
  background-color: #fff;
  color: #000;
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0;
  }
`;
