import { motion } from 'framer-motion';
import styled from 'styled-components';
import { animationSlideRight, bounceSlow } from '../../animation';

type IconType = {
  source: string;
  alt: string;
  title: string;
  bg?: string;
};

export const TechIcon = ({ source, alt, title, bg }: IconType) => {
  return (
    <StyledMotionImage
      initial="out"
      animate="in"
      exit="out"
      variants={animationSlideRight}
      transition={bounceSlow}
      src={source}
      alt={alt}
      title={title}
      style={{
        background: bg ? bg : undefined,
      }}
    />
  );
};

const StyledMotionImage = styled(motion.img)`
  @media screen and (max-width: 768px) {
    margin: 0 2rem;
  }
`;
