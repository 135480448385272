export const animationSlideLeft = {
  in: {
    x: 0,
  },
  out: {
    x: '100vw',
  },
};

export const animationSlideRight = {
  in: {
    x: 0,
    scale: 1,
  },
  out: {
    x: '-100vw',
    scale: 0.1,
  },
};

export const animationSlideFromTop = {
  in: {
    y: 0,
    scale: 1,
  },
  out: {
    y: '100vh',
    scale: 0.1,
  },
};

export const bounceSlow = { duration: 2, type: 'spring', bounce: 0.3 };
export const bounceFast = { duration: 1.4 };
