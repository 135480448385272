import { useContext, createContext, useState } from 'react';

type FormData = {
  name: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
};

const FormContext = createContext({
  formData: {} as FormData,
  handleChange: (e: any) => {},
  clearForm: () => {},
});

const initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  subject: '',
  message: '',
};

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const [formData, setFormData] = useState<FormData>(initialState);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData(initialState);
  };

  return (
    <FormContext.Provider value={{ formData, handleChange, clearForm }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
