import { Route, Routes } from 'react-router-dom';

import { Technologies } from '../pages/Technologies';
import { Contact } from '../pages/Contact';
import { Projects } from '../pages/Projects';
import { NavBar } from './NavBar';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Home } from '../pages/Home';
import { NavDrawer } from './NavDrawer';
import { ProjectInfoDrawer } from './ProjectInfoDrawer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from './ErrorBoundary';
import Error500 from './Error/500';
import Error404 from './Error/404';
import { useAppContext } from 'context/AppContext';
import { Maintenance } from './Maintenance';
import TrackPageViews from './TrackPageViews';

const App = () => {
  const { isBlocked } = useAppContext();

  if (isBlocked) {
    return <Maintenance />;
  }

  if (isBlocked !== null && !isBlocked) {
    return (
      <>
        <TrackPageViews />
        <ToastContainer position="top-center" />
        <NavBar />
        <NavDrawer />
        <ProjectInfoDrawer />
        <AnimatePresence>
          <Wrapper>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="projects" element={<Projects />} />
              <Route path="Technologies" element={<Technologies />} />
              <Route
                path="contact"
                element={
                  <ErrorBoundary fallback={<Error500 />}>
                    <Contact />
                  </ErrorBoundary>
                }
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Wrapper>
        </AnimatePresence>
      </>
    );
  }
};

const Wrapper = styled.main`
  margin: 0 8rem;

  @media screen and (max-width: 768px) {
    margin: 0 2rem;
  }
`;

export default App;
