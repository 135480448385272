/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Soft UI Dashboard PRO React base styles
import typography from 'theme/base/typography';

// Images
import error500 from 'images/error-500.png';

interface Error500Props {
  error: string;
}

function Error500() {
  const { d1, d3, d4, d5 } = typography;

  return (
    <SoftBox my={18} height="calc(100vh - 18rem)">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} container alignItems="center">
          <Grid item xs={12} lg={5}>
            <SoftBox
              fontSize={{
                xs: d5.fontSize,
                sm: d4.fontSize,
                md: d3.fontSize,
                lg: d1.fontSize,
              }}
              lineHeight={1.2}
            >
              <SoftTypography
                variant="inherit"
                color="warning"
                textGradient
                fontWeight="bold"
              >
                Oh No!
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="h2" color="text" fontWeight="bold">
              Something went wrong
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="h4" color="text" opacity={0.8}>
                We suggest you to go to the homepage while we solve this issue.
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={2}>
              <SoftButton
                component={Link}
                to="/"
                variant="gradient"
                color="warning"
              >
                go to homepage
              </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <SoftBox
              component="img"
              src={error500}
              alt="error-404"
              width="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Error500;
