import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const isDev = process.env.NODE_ENV === 'development';

    if (isDev) return;

    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID as string, {});

    const sendPageView = (path: string) => {
      ReactGA.set({ page: path });
      ReactGA.send({ hitType: 'pageview', page: path });
    };

    sendPageView(location.pathname);
  }, [location]);

  return null;
};

export default TrackPageViews;
